body {
    background-color:#d3d3d354;
}

@media print {
    body::after {
        content:none!important;
        background:none!important;
    }
}

.page{
    padding-top:5px;
    padding-bottom:5px;
}

.page-content{
    padding:10px;
    box-shadow:rgb(84 88 91 / 25%) 0px 4px 16px, rgb(52 52 55 / 5%) 0px 8px 32px;
    overflow:hidden;
    margin-bottom:10px;
    background:#fff;
}

.body-title{
    color: darkmagenta;
}

.reset-styles * {
    all: unset;
    box-sizing: border-box;
    display: revert;
    font-family: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
    border: 0;
    text-decoration: none;
    list-style: none;
  }